import React from 'react';

import { WatermarkOne } from './watermarkStyles/One/WatermarkOne';
import { WatermarkTwo } from './watermarkStyles/Two/WatermarkTwo';
import { WatermarkThree } from './watermarkStyles/Three/WatermarkThree';
import { WatermarkFour } from './watermarkStyles/Four/WatermarkFour';

export const Watermark = ({ markStyle }) => {
    if(markStyle === 1){
        return <WatermarkOne /> 
    } else if(markStyle === 2){
        return <WatermarkTwo /> 
    } else if(markStyle === 3){
        return <WatermarkThree /> 
    } else if(markStyle === 4){
        return <WatermarkFour /> 
    } else {
        // Default to first watermark style
        return <WatermarkOne />
    }
}