import React from 'react'
import { Seo } from '../Seo/Seo'
import { Header, Footer } from '../../sections'

import './../../styles/style.scss'

export const Layout = ({ children }) => {
    return (
        <>
            <Seo />
            <Header />
            {children}
            <Footer />
        </>
    )
}