import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { TeamMember } from '../Team';

import video from './../../../videos/optimized/team/kiefer-video.mp4';
import placeholderImg from './../../../images/CollectiveHaus_2023_VideoLoader.jpg';

export const Kiefer = () => {
    const data = useStaticQuery(portraitQuery);
    const portrait = data.file.childImageSharp.gatsbyImageData;


    return (
        <TeamMember
            portrait={portrait}
            portraitAlt="Kiefer rocks a big smile wearing his favourite hat."
            video={video}
            videoAlt="Kiefer the barber tossing around a football in his hands as he smiles into the camera."
            videoPlaceholder={placeholderImg}
            name="Kiefer"
            title="Barber"
            shortBio="Kiefer is an eager barber, Star Wars fiend and 10/10 wholesome dude."
        >
            <p>
              Kiefer is the new kid on the block. Having been curious about barbering for some time, he took the leap and enrolled in barbering school. When he’s not busy learning the ropes, developing different techniques or building relationships with clients, he can be found on the gridiron playing football or lifting in the gym.
            </p>
        </TeamMember>
    )
}

const portraitQuery = graphql`{
  file(name: {glob: "*-Kiefer-Portrait"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
}
`
