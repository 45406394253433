// extracted by mini-css-extract-plugin
export var anchorUnderlineIn = "Header-module--anchor-underline-in--hwUSQ";
export var anchorUnderlineOut = "Header-module--anchor-underline-out--rSP5+";
export var desktopNav = "Header-module--desktopNav--0H3nC";
export var hasLoaded = "Header-module--has-loaded--HVTYH";
export var header = "Header-module--header--GT9a6";
export var logo = "Header-module--logo--zY08J";
export var mobileBookNow = "Header-module--mobileBookNow--8Md9t";
export var mobileBtn = "Header-module--mobileBtn--i4UNX";
export var mobileButtons = "Header-module--mobileButtons--0J5av";
export var mobileCloseButton = "Header-module--mobileCloseButton--V6SVw";
export var mobileMenuButton = "Header-module--mobileMenuButton--+C5Qt";
export var mobileNav = "Header-module--mobileNav--LMVuj";
export var watermark = "Header-module--watermark--MxiRO";