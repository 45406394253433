import React from 'react';
import { GatsbyImage } from "gatsby-plugin-image";

import * as s from './Images.module.scss'

import { ResponsiveContainer } from './../ResponsiveContainer/ResponsiveContainer';

export const Images = ({ images, aspectRatio }) => {
    let imageArray = [];

    if(images.length > 0){
        imageArray = [...images]
    } else {
        imageArray.push(images);
    }

    let imagesMarkup = imageArray.map((image, index) => {
        return (
            <ResponsiveContainer 
                key={index} 
                aspectRatio={aspectRatio}
            >
                <GatsbyImage image={image.src} style={{ position: "absolute" }} alt={image.alt} />
            </ResponsiveContainer>
        );
    });

    if(imageArray.length > 1){
        return (
            <div className={s.imageGrid}>
                { imagesMarkup }
            </div>
        )
    } else {
        return imagesMarkup
    }
}