import React from 'react'

import * as s from './SocialIcons.module.scss';

import InstagramSVG from './../../images/svgs/instagram.svg';
import FacebookSVG from './../../images/svgs/facebook.svg';

export const SocialIcons = () => {
    return (
        <ul className={s.socialIcons}>
            <li>
                <a href="https://www.instagram.com/collectivehaus" target="_blank" rel="noreferrer">
                    <InstagramSVG />
                    <span className="sr-only">Instagram</span>
                </a>
            </li>
            <li>
                <a href="https://www.facebook.com/CollectiveHausSudbury/" target="_blank" rel="noreferrer">
                    <FacebookSVG />
                    <span className="sr-only">Facebook</span>
                </a>
            </li>
        </ul>
    )
}