import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { TeamMember } from '../Team';

import video from './../../../videos/optimized/team/jimmy-video.mp4';
import placeholderImg from './../../../images/CollectiveHaus_2023_VideoLoader.jpg';

export const Jimmy = () => {
    const data = useStaticQuery(portraitQuery);
    const portrait = data.file.childImageSharp.gatsbyImageData

    return (
        <TeamMember
            portrait={portrait}
            portraitAlt="Jimmy the barber sporting big glasses and a big smile"
            video={video}
            videoAlt="Jimmy, barber at Collective Haus, chuckling and looking into the camera"
            videoPlaceholder={placeholderImg}
            name="Jimmy"
            title="Barber"
            shortBio="Jimmy is a taper-loving barber, wheely-popping bike enthusiast and proud dog dad."
        >
            <p>
               Having spent a lot of time in garages with his dad as a youngster, Jimmy was inspired by the music and hair from the classic rock’n’roll car culture. After his release from the Canadian Armed Forces in 2014, Jimmy made his first foray into the world of barbering with an apprenticeship under Jason Pautler. His appreciation for discipline, hard work and a good haircut drove him to become the Grand Poobah at Collective Haus. With Jimmy, you get what you pay for—quality cuts without the bullshit.
            </p>
        </TeamMember>
    )
}

const portraitQuery = graphql`{
  file(name: {glob: "*-Jimmy-Portrait"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
}
`
