import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image";

import * as s from './Services.module.scss'
import Heart from './../../images/svgs/heart.svg';

export const Grid = ({ children, calloutText }) => {
    return (
       <div className="container small">
           <div className={s.grid}>
               {children}
               {calloutText &&
                    <div>
                        {calloutText}
                    </div>
                }
           </div> 
       </div>
    );
}

export const Item = ({ image, text }) => {
    return (
        <div className={`${s.item} ${image ? s.hasImg : s.noImg}`}>
            { image &&
                <div className={s.image}>
                    <GatsbyImage image={image.src} alt={image.alt} style={{ position: "absolute" }} />
                </div>
            }
            { text &&
                <div className={`${s.text} ${image ? s.hasImg : s.noImg} h6`}>
                    {image ? '' : <Heart />}
                    {text}
                </div>
            }
        </div>
    );
}