import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { TeamMember } from '../Team';

import video from './../../../videos/optimized/team/emma-video.mp4';
import placeholderImg from './../../../images/CollectiveHaus_2023_VideoLoader.jpg';

export const Emma = () => {
    const data = useStaticQuery(portraitQuery);
    const portrait = data.file.childImageSharp.gatsbyImageData;

    return (
        <TeamMember
            portrait={portrait}
            portraitAlt="Emma, Owner, and colour wizard at Collective Haus smiling into the camera."
            video={video}
            videoAlt="Emma the hairstylist blows a kiss into the camera."
            videoPlaceholder={placeholderImg}
            name="Emma"
            title="Hairstylist"
            shortBio="Emma is the Lord High Everything Else, Owner, and colour wizard at Collective Haus."
        >
            <p>
                Born with a glowing orange Afro, Emma was naturally drawn to playing with hair. After doing a highschool co-op at a local salon, this colourful Myspace teen packed her bags and moved to Ottawa to attend Marvel Beauty School in 2010. She then landed her dream apprenticeship at Studio B where she was exposed to the wonderful life of a hairstylist. Being a Northern girl at heart, Emma returned home to Sudbury in 2013 with knowledge, skills and experiences she’ll never forget, like training with the late Oribe at La Fama in Miami. After working at a few salons, Emma felt the spark to start her own very own venture, Collective Haus.
            </p>

            <p>
                When Emma isn’t taking care of clients, you can find her on the couch snuggling up with her hairless cat Binx, playing her favourite Nu Wave tunes on bass or hanging out with her other half, Jimmy, and their newest fur-baby, Marge.
            </p>
        </TeamMember>
    )
}

const portraitQuery = graphql`{
  file(name: {glob: "*-Emma-Portrait"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
}
`
