import React from 'react';

import * as s from './CheckmarkList.module.scss';
import CheckmarkCircIcon from './../../images/svgs/checkmark-circ.svg';

export const Wrapper = ({ children }) => {
    return (
        <ul className={s.checkmarkList}>
            {children}
        </ul>
    );
}

export const Item = ({ children }) => {
    return (
        <li>
            <CheckmarkCircIcon />
            {children}
        </li>
    )
}