import React from 'react'
import { Watermark } from '../Watermarks';

import * as s from './Text.module.scss';

export const Text = ({ children, watermarkNum = 1, showWatermark = true, style, spacing, spacingTop = true, spacingBottom = true, alignText }) => {
    return (
        <div className={`${s.section} text`}>
            <div className={`${s.section} container small`}>
                <div className={`${s.text} ${spacing === 'lg' ? s.spacingLg : ''} ${!spacingTop ? s.noSpacingTop : ''} ${!spacingBottom ? s.noSpacingBottom : ''}`} style={{ ...style }}>
                    {children}
                </div>
            </div>
            {showWatermark &&
                <Watermark markStyle={watermarkNum} />
            }
        </div>
    )
}