import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { TeamMember } from '../Team';

import video from './../../../videos/optimized/team/andy-video.mp4';
import placeholderImg from './../../../images/CollectiveHaus_2023_VideoLoader.jpg';

export const Andy = () => {
    const data = useStaticQuery(portraitQuery);
    const portrait = data.file.childImageSharp.gatsbyImageData;

    return (
        <TeamMember
            portrait={portrait}
            portraitAlt="Andy, the apprentice barber smiles confidently."
            video={video}
            videoAlt="Andy, apprentice barber at Collective Haus smiles while running a comb through their hair."
            videoPlaceholder={placeholderImg}
            name="Andy"
            title="Apprentice Barber"
            shortBio="Andy is a keen apprentice barber and full time toe tappin’ hip shaker."
        >
            <p>
              I grew up inspired by fashion, watching models strut the runway on tv or pose in magazine. <br />
              I’ve always been passionate about art but never knew what route to take to make a career out of it. <br />
              I graduated from boreal’s hairdressing program.  <br />
              From there I knew I wanted to work with hair and after giving salons a go, I realized I was better fit to be a barber. <br />
              Since apprenticing under Jimmy I’ve learned to give quality services that I take pride in. <br />
              On my time off I enjoy hanging out with my cat and watching anime or picking up my 35mm camera and going for a walk downtown.
            </p>
        </TeamMember>
    )
}

const portraitQuery = graphql`{
  file(name: {glob: "*-Andy-Portrait"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
}
`
