import React from 'react'

import * as s from './../SpotifyBar/SpotifyBar.module.scss';

import SpotifyLogoSVG from './../../images/svgs/spotify.svg';

export const SpotifyBar = ({ 
    spotifyLink, 
    children 
}) => {
    return (
        <div className="container large">
            <a className={s.spotify} href={spotifyLink ? spotifyLink : ''} target="_blank" rel="noreferrer">
                <div>
                    {children}
                </div>
                <div className={s.spotifyCode}>
                    <SpotifyLogoSVG />
                </div>
            </a>
        </div>
    )
}