import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image";

import * as s from './TextAndImage.module.scss'

export const TextAndImage = ({ children, image, aspectRatio}) => {
    return (
        <div className={`columns is-gapless ${s.wrapper}`}>
            <div className="column is-6">
                <div className={s.image}>
                    <GatsbyImage image={image.src} style={{ position: "absolute" }} alt={image.alt} />
                </div>
            </div>
            <div className="column is-6">
                <div className={`${s.text} ${s.box}`}>
                    {children}
                </div>
            </div>
        </div>
    );
}