import React from "react"

import * as s from './../VerticalTitle/VerticalTitle.module.scss'

export const VerticalTitle = ({ children, title, heading = false }) => {
    const Tag = heading ? `h${heading}` : 'span'
    return (
        <div className="container large">
            <Tag className={`${s.title} ${s.top}`}>{title}</Tag>
            <div className={`${s.containerFix} container`}>
                { children }
            </div>
            <span aria-hidden="true" className={`${s.title} ${s.bottom}`}>{title}</span>
        </div>
    );
}