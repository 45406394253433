import React, { useState } from "react"
import { GatsbyImage } from "gatsby-plugin-image";
import Modal from 'react-modal';

import { VerticalTitle } from '../../components/VerticalTitle/VerticalTitle'
import { ResponsiveContainer } from '../../components/ResponsiveContainer/ResponsiveContainer'

// Team Members
import { Emma } from './TeamMembers/Emma';
import { Jimmy } from './TeamMembers/Jimmy';
import { Victoria } from './TeamMembers/Victoria';
import { Kiefer } from './TeamMembers/Kiefer';
import { Andy } from './TeamMembers/Andy';
import { Sharon } from './TeamMembers/Sharon';

import Logo from './../../images/svgs/logo.svg';
import CloseIcon from './../../images/svgs/close.svg';
import ArrowRightIcon from './../../images/svgs/arrow-right.svg'

import * as s from './Team.module.scss';

Modal.setAppElement('#___gatsby');

export const Team = () => {
    return (
        <div id="the-collective" className={s.team}>
            <VerticalTitle title="The Collective">
                <div className={s.teamMemberGrid}>
                    <Emma />
                    <Jimmy />
                    <Victoria />
                    <Kiefer />
                    <Andy />
                    <Sharon />
                </div>
            </VerticalTitle>
        </div>
    );
    }

export const TeamMember = ({ portrait, portraitAlt, video, videoAlt, videoPlaceholder, name, title, shortBio, children }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openModal = () => {
        setModalIsOpen(true);
    }

    const closeModal = () => {
        setModalIsOpen(false);
    }

    return <>
        <div className={s.teamMember}>
            <ResponsiveContainer className={s.portrait}>
                { portrait
                ?  <GatsbyImage
                    image={portrait}
                    style={{ position: "absolute" }}
                    alt={portraitAlt ? portraitAlt : `Portrait of ${name}`} />
                : <Logo className={s.logo} />
                }
            </ResponsiveContainer>
            <div className={s.content}>
                <span className={s.name}><strong>{name}</strong> - {title}</span>
                <p>{shortBio}</p>
                <button onClick={openModal} className={`h6 underline-hover ${s.link}`}>About {name}<ArrowRightIcon /></button>
            </div>
        </div>
        <Modal 
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            preventScroll={true}
            onAfterOpen={() => {
                document.body.style.overflow = 'hidden';
                document.body.style.width = '100%';
                document.body.style.height = '100%'
                document.body.style.top = `-${window.scrollY}px`
                document.body.style.position = 'fixed'
            }}
            onAfterClose={() => {
                const scrollY = document.body.style.top
                document.body.removeAttribute("style")
                document.documentElement.style.scrollBehavior = "unset";
                window.scrollTo(0, parseInt(scrollY || '0') * -1)
                document.documentElement.style.scrollBehavior = "smooth";
            }}

            className={ s.modal }
            overlayClassName={ s.overlay }
        >
            <button className={`button ${s.close}`} onClick={closeModal}>
                <span className="sr-only">Close modal</span>
                <CloseIcon />
            </button>
            <ResponsiveContainer aspectRatio={"16:7"} objectPosition="top center" className={s.portrait}>
                {
                video || portrait 
                    ?   <>
                            { video 
                                ? <video 
                                        title={videoAlt ? videoAlt : ''}
                                        className={s.video}
                                        src={video}
                                        autoPlay 
                                        playsInline 
                                        muted 
                                        loop 
                                        poster={videoPlaceholder}
                                    ></video>
                                : <GatsbyImage
                                image={portrait}
                                style={{ position: "absolute" }}
                                alt={`Portrait of ${name}`}
                                imgStyle={{
                                    objectPosition: 'top center', 
                                    filter: "grayscale(1)"
                                }} />
                            }
                        </>
                    :   <Logo className={s.logo} />
                }
            </ResponsiveContainer>
            <div className={s.content}>
                <div className="columns">
                    <div className="column is-3">
                        <span className={s.name}>
                            <strong>{name}</strong> - {title}
                        </span>
                        <p>{shortBio}</p>

                    </div>
                    <div className="column is-7 is-offset-1">
                        {children}
                    </div>
                </div>
            </div>
        </Modal>
    </>;
}
