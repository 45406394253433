import React, { useEffect, useRef } from 'react'
import Slider from 'react-slick'
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger";

import LogoWatermark from './../../images/svgs/logo-full.svg';
import Logo from './../../images/svgs/logo-alternative.svg';
import ArrowLeftSVG from './../../images/svgs/arrow-left.svg';
import ArrowRightSVG from './../../images/svgs/arrow-right.svg';

import * as s from './Testimonials.module.scss';

export const Testimonials = () => {
    const watermarkRef = useRef(null);
    const slider = useRef(null);

    const onPrevClick = () => {
        slider.current.slickPrev();
    }

    const onNextClick = () => {
        slider.current.slickNext();
    }

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger)
        const svgWatermark = watermarkRef.current?.querySelector(".circle");

        if(svgWatermark){
            const anim = gsap.timeline({
                scrollTrigger: {
                    trigger: watermarkRef.current,
                },
            });

            anim.fromTo(svgWatermark, 
                {
                    rotate: 0,
                    transformOrigin: 'center center',
                },
                {
                    rotate: 360,
                    repeat: -1,
                    duration: 250,
                    ease: "linear"
                }
            )
        }
    }, []);

    return (
        <div className={`${s.testimonials} container small`}>
            <h2 className="sr-only">Testimonials</h2>
            <Logo className={s.logo} />
            <Slider ref={slider} fade={true} prevArrow={<PrevArrow handleClick={onPrevClick} />} nextArrow={<NextArrow handleClick={onNextClick} />}>
                <Testimonial
                    quote="I have been getting my hair cut by Emma for almost a decade now. The staff is so friendly and knowledgeable, they really go above and beyond for their clients. I even reached out to purchase some product when I was living out of town and boy did they deliver!"
                    citation="Alec Moyle"
                />
                <Testimonial
                    quote="Great atmosphere and great stylists! Emma made me feel comfortable with her bubbly personality while bringing my hair vision to life. Highly recommend!"
                    citation="Kristen Morrisson"
                />
                <Testimonial
                    quote="The folks at Collective Haus are some of the nicest, coolest, talented, and skilled people. Every time I go, I’m greeted with warm smiles, great conversation and they always make you feel comfortable and welcome. Emma has been coloring my hair for a while now and I’ve never been disappointed!"
                    citation="Terri Scherzinger"
                />
                 <Testimonial
                    quote="This place is hands down the best shop in town if you wanna come out looking fresh. Great people who do great work."
                    citation="Hunter Holub"
                />
                <Testimonial
                    quote="Best in town, bar none! Jimmy and co. will always take care of ya."
                    citation="Farris Gulli"
                />
                <Testimonial
                    quote="The gang at Collective Haus are just amazing, from the barbershop to the salon upstairs you just can’t ask for better, not to mention the good chats and all around love they have for their jobs."
                    citation="Natalie Thompson"
                />
                <Testimonial
                    quote="Buncha cool people who are awesome at what they do! get a dank haircut, have some laughs. what's not to love."
                    citation="Benoit Gosselin"
                />
            </Slider>
            <div ref={watermarkRef}>
                <LogoWatermark className={s.logoMark} />
            </div>
        </div>
    );
}

const Testimonial = ({quote, citation}) => (
    <blockquote>
        <span className="h3">{quote}</span>
        <footer>
            — <cite>{citation}</cite>
        </footer>
    </blockquote>
)

const PrevArrow = ({ handleClick }) => (
    <button type="button" className="slick-arrow slick-prev" style={{ display: "block" }} onClick={handleClick}>
        <div className="sr-only">Previous</div>
        <ArrowLeftSVG />
    </button>
)

const NextArrow = ({ handleClick }) => (
    <button type="button" className="slick-arrow slick-next" style={{ display: "block" }} onClick={handleClick}>
        <div className="sr-only">Next</div>
        <ArrowRightSVG />
    </button>
)
