import React, { useRef, useEffect } from 'react';
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger";

import * as s from './WatermarkFour.module.scss'

import Watermark from './../../../../images/svgs/text-watermark-two.svg';

export const WatermarkFour = () => {
    const watermarkSVG = useRef(null)
    
    useEffect(() => {
        const svgTextPath = watermarkSVG.current?.getElementsByTagName("textPath")[0];
        let startOffsetAmount = "-100%";

        gsap.registerPlugin(ScrollTrigger)

        if(svgTextPath){
            const anim = gsap.timeline({
                scrollTrigger: {
                  trigger: svgTextPath,
                  start: "center 100%",
                  scrub: 1
                },
            })

            anim.fromTo(svgTextPath, 
                {
                    attr: { startOffset: startOffsetAmount },
                },
                {
                    attr: { startOffset: "10%" },
                }
            )
        }
    }, [])

    return (
        <div ref={watermarkSVG} className={s.watermarkContainer}>
            <Watermark className={s.watermark} />
        </div>
    );
}