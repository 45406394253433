import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

// import * as s from './BarberShop.module.scss';

import { VerticalTitle } from '../../components/VerticalTitle/VerticalTitle';
import { Images } from '../../components/Images/Images';
import { Text } from '../../components/Text/Text';
import * as Service from '../../components/Services/Services';
import * as CheckmarkList from '../../components/CheckmarkList/CheckmarkList';

export const BarberShop = () => {
    const data = useStaticQuery(imageQuery)

    return (
      <div id="barber-shop">
          <VerticalTitle title="Barber Shop" heading="2">
            <Images
              aspectRatio={"16:9"}
              images={{
                src: data.allFile.edges[0].node.childImageSharp.gatsbyImageData,
                alt: "Jimmy Giroux, barber at Collective Haus tapering a client’s hair with a electric razor."
              }}
            />
            <Text showWatermark={false}>
              <h3 className="h1 font-serif">Get a timeless haircut or shave in a chill, non-pretentious environment.</h3>
              <p>
              We specialize in classic cuts and shaves, but are always down to try something new, so don’t be shy! There are no attitudes in this barber shop.
              </p>
            </Text>
            <Images
              aspectRatio={"16:9"}
              images={[ 
                { 
                  src: data.allFile.edges[1].node.childImageSharp.gatsbyImageData,
                  alt: "Kiefer fading out the hair of a customer with a straight razor"
                },
                { 
                  src: data.allFile.edges[2].node.childImageSharp.gatsbyImageData,
                  alt: " Persons of Interest Beyond the Pines Eau de Parfum packaging."
                }
              ]}
            />
            <Text showWatermark={false}>
              <p className="h2 font-serif">We're a barber shop.</p>
              <p>We cut your hair, shave your face and sell you stuff to keep you looking and smelling fresh.</p>
            </Text>
            <Service.Grid>
              <Service.Item
                image={{ 
                  src: data.allFile.edges[3].node.childImageSharp.gatsbyImageData,
                  alt: "Close up photo of Jimmy Giroux, barber at Collective Haus tapering a client’s hair with a electric razor."
                }}
                text="Cuts"
              />
              <Service.Item
                image={{
                  src: data.allFile.edges[4].node.childImageSharp.gatsbyImageData,
                  alt: "Close up photo of Jimmy Giroux giving a client a straight razor shave."
                }}
                text="Shaves"
              />
              <Service.Item
                image={{ 
                  src: data.allFile.edges[5].node.childImageSharp.gatsbyImageData,
                  alt: "Persons of Interest 45 Charcoal Incense Pyres jar."
                }}
                text="Extras"
              />
              <Service.Item
                text="PSST! Our pricing is gender neutral"
              />
            </Service.Grid>
            <Text showWatermark={false} spacingBottom={false}>
              <h3 className={`h2 font-serif`}>Our Products</h3>
              <div className="columns">
                <div className="column is-half">
                  <p>We want to make sure you have not only the best hair you can have, but also know how to maintain and treat it when you are out of our chairs.</p>
                </div>
                <div className="column is-offset-2">
                  <CheckmarkList.Wrapper>
                    <CheckmarkList.Item>
                      Styling balm
                    </CheckmarkList.Item>
                    <CheckmarkList.Item>
                      Pomades
                    </CheckmarkList.Item>
                    <CheckmarkList.Item>
                      Beard oil
                    </CheckmarkList.Item>
                    <CheckmarkList.Item>
                      Oral Care
                    </CheckmarkList.Item>
                    <CheckmarkList.Item>
                      Body Wash
                    </CheckmarkList.Item>
                    <CheckmarkList.Item>
                     Fragrances
                    </CheckmarkList.Item>
                  </CheckmarkList.Wrapper>
                </div>
              </div>
            </Text>
          </VerticalTitle>
      </div>
    );
}

const imageQuery = graphql`{
  allFile(filter: {name: {glob: "*-Barber-Shop-*"}}, sort: {fields: name}) {
    edges {
      node {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  }
}
`
