import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

// import * as s from './BeautyParlor.module.scss';

import { VerticalTitle } from './../../components/VerticalTitle/VerticalTitle';
import { Images } from './../../components/Images/Images';
import { Text } from './../../components/Text/Text';
import * as Service from './../../components/Services/Services';
import * as CheckmarkList from './../../components/CheckmarkList/CheckmarkList';
import { TextAndImage } from './../../components/TextAndImage/TextAndImage';

export const BeautyParlor = () => {
    const data = useStaticQuery(imageQuery)

    return (
      <div id="beauty-parlor">
          <VerticalTitle title="Beauty Parlor" heading="2">
            <Images
              aspectRatio={"16:9"}
              images={[ 
                { 
                  src: data.allFile.edges[0].node.childImageSharp.gatsbyImageData, 
                  alt: "Owner and Stylist Emma Quinn working on a client’s hair in front of a big picture window."
                } 
              ]}
            />
            <Text showWatermark={false}>
              <h3 className="h1 font-serif">
                Treat yo’self. You and your locks deserve it!
              </h3>
              <p>
              We do everything under the rainbow: regular cuts, colouring and styling. Don’t tell anyone, but bright hair colours really make our hearts flutter. We’re experts in what we do and we’re ready for you.
              </p>
            </Text>
            <Images
              aspectRatio={"16:9"}
              images={[ 
                { 
                  src: data.allFile.edges[1].node.childImageSharp.gatsbyImageData, 
                  alt: "Pink Oribe Serene Balancing Shampoo and Thickening Treatment Spray bottles sitting in pink pot." 
                }, 
                { 
                  src: data.allFile.edges[2].node.childImageSharp.gatsbyImageData, 
                  alt: "Products and merchandise displayed in salon area of Collective Haus." 
                } 
              ]}
            />
            <Text showWatermark={false}>
              <div className="columns">
                <div className="column is-8">
                  <p className="h2 font-serif">
                    We don't do perms, but we do everything else.
                  </p>
                </div>
              </div>
            </Text>
            <Service.Grid>
              <Service.Item
                image={{ 
                  src: data.allFile.edges[3].node.childImageSharp.gatsbyImageData, 
                  alt: "Barber tools and scissors arranged on desk" 
                }}
                text="Cuts"
              />
              <Service.Item
                image={{
                  src: data.allFile.edges[4].node.childImageSharp.gatsbyImageData,
                  alt: "Emma Quinn, owner of Collective Haus, applying colour to client’s hair"
                }}
                text="Colouring / Styling"
              />
              <Service.Item
                image={{
                  src: data.allFile.edges[5].node.childImageSharp.gatsbyImageData,
                  alt: "Photo of hair products arranged on a table"
                }}
                text="Extras"
              />
              <Service.Item
                text="PSST! Our pricing is gender neutral"
              />
            </Service.Grid>
            <Text showWatermark={false}>
              <h3 className={`h2 font-serif`}>Our Products</h3>
              <div className="columns">
                <div className="column is-half">
                  <p>
                    We carry a wide range of products from Oribe that will make you look and feel fantastic. Oribe products are formulated without parabens, sulfates (SLS or SLES), mineral oil and petrolatum. They are all vegan, cruelty-free, gluten-free and color-safe.
                  </p>
                </div>
                <div className="column is-5 is-offset-1">
                  <CheckmarkList.Wrapper>
                    <CheckmarkList.Item>
                      Dry Texturizing Spray
                    </CheckmarkList.Item>
                    <CheckmarkList.Item>
                      Balm d’Or Heat Styling Shield
                    </CheckmarkList.Item>
                    <CheckmarkList.Item>
                      Renewal Remedies Treatment Experience
                    </CheckmarkList.Item>
                    <CheckmarkList.Item>
                     Côte d’Azur Eau de Parfum
                    </CheckmarkList.Item>
                    <CheckmarkList.Item>
                     Dessertland Scented Candle
                    </CheckmarkList.Item>
                    <CheckmarkList.Item>
                      Valley Of Flowers Replenishing Body Wash
                    </CheckmarkList.Item>
                  </CheckmarkList.Wrapper>
                </div>
              </div>
            </Text>
            <TextAndImage 
            image={{
              src: data.allFile.edges[6].node.childImageSharp.gatsbyImageData,
              alt: "Oribe Balmessence Lip Treatment box sitting in pink pot."
            }}
            aspectRatio="100%"
            >
              <h3 className="text-black">If you don’t know Oribe then get ready to learn.</h3>
              <p>
                Oribe is about an experience. The packaging and fragrances were designed to enhance that experience and evoke a feeling, place, style or attitude. 
              </p>
            </TextAndImage>
          </VerticalTitle>
      </div>
    );
}

const imageQuery = graphql`{
  allFile(filter: {name: {glob: "*-Beauty-Parlor-*"}}, sort: {fields: name}) {
    edges {
      node {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  }
}
`