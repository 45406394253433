import React, { useState, useEffect, useRef } from 'react'
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger";
import FocusTrap from 'focus-trap-react';

import Logo from './../../images/svgs/logo.svg';
import MenuIcon from './../../images/svgs/menu.svg';
import CloseIcon from './../../images/svgs/close.svg';
import LogoWatermark from './../../images/svgs/logo-full.svg';

import * as s from './Header.module.scss';

export const Header = () => {
    const [toggleMenu, setToggleMenu] = useState(false);

    useEffect(() => {
        if(toggleMenu === true){
            window.scroll(0, 0);
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }
    }, [toggleMenu])

    return (
        <>
            <header className={`container ${s.header}`}>
                <div className={s.logo}>
                    <a href="/">
                        <span className="sr-only">
                            <h1>Collective Haus</h1>
                        </span>
                        <Logo />
                    </a>
                </div>
                <DesktopMenu />
                <div className={s.mobileButtons}>
                    <a 
                        className={`button ${s.mobileBookNow}`} 
                        href="https://www.vagaro.com/collectivehaus/book-now" 
                        target="_blank" 
                        rel="noreferrer"
                    >
                        Book Now
                    </a>
                    <button 
                        className={s.mobileMenuButton}
                        onClick={() => setToggleMenu(!toggleMenu)}
                    >
                        <span className="sr-only">Open menu</span>
                        <MenuIcon />
                    </button>
                </div>
            </header>
            { toggleMenu &&
                <MobileMenu 
                    toggleMenu={toggleMenu} 
                    setToggleMenu={setToggleMenu} 
                />
            }
        </>
    )
}

const DesktopMenu = () => {
    let [isLoaded, setIsLoaded] = useState(false);

    // Hacky way to hide the bottom border on load 
    useEffect(() => {
        setTimeout(() => {
            setIsLoaded(true);
        }, 500)
    }, []);

    return (
        <nav className={s.desktopNav}>
            <h2 className="sr-only">Navigation</h2>
            <a className={isLoaded ? s.hasLoaded : ''} href="#the-collective">The Collective</a>
            <a className={isLoaded ? s.hasLoaded : ''} href="#beauty-parlor">Beauty Parlor</a>
            <a className={isLoaded ? s.hasLoaded : ''} href="#barber-shop">Barber Shop</a>
            <a className={isLoaded ? s.hasLoaded : ''} href="#faq">FAQ</a>
            <a className="button" href="https://www.vagaro.com/collectivehaus/book-now" target="_blank" rel="noreferrer">Book Now</a>
        </nav>
    )
}

const MobileMenu = ( {toggleMenu, setToggleMenu}) => {
    const watermarkRef = useRef(null);

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger)
        const svgWatermark = watermarkRef.current?.querySelector(".circle");

        if(svgWatermark){
            gsap.fromTo(svgWatermark, 
                {
                    rotate: 0,
                    transformOrigin: 'center center',
                },
                {
                    rotate: 360,
                    repeat: -1,
                    duration: 100,
                    ease: "linear"
                }
            )
        }
    }, []);

    return (

        <FocusTrap>
            <div className={s.mobileNav}>
                    <button 
                        className={s.mobileCloseButton}
                        onClick={() => setToggleMenu(!toggleMenu)}
                    >
                        <span className="sr-only">Close menu</span>
                        <CloseIcon />
                    </button>
                    <nav>
                        <h2 className="sr-only">Navigation</h2>
                        <a onClick={() => setToggleMenu(false)} href="#the-collective">The Collective</a>
                        <a onClick={() => setToggleMenu(false)}  href="#beauty-parlor">Beauty Parlor</a>
                        <a onClick={() => setToggleMenu(false)}  href="#barber-shop">Barber Shop</a>
                        <a onClick={() => setToggleMenu(false)}  href="#faq">FAQ</a>
                        <a className={`${s.mobileBtn} button`} href="https://www.vagaro.com/collectivehaus/book-now" target="_blank" rel="noreferrer">Book Now</a>
                    </nav>
                    <div ref={watermarkRef}>
                        <LogoWatermark className={s.watermark} />
                    </div>
            </div>
        </FocusTrap>
    )
}