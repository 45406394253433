import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";

import { ResponsiveContainer } from './../../components/ResponsiveContainer/ResponsiveContainer'
import { VerticalTitle } from '../../components/VerticalTitle/VerticalTitle'

import * as s from './Contact.module.scss'

export const Contact = () => {
    const data = useStaticQuery(imageQuery);

    return (
      <div className={s.contact}>
          <VerticalTitle title="Contact" heading="2">
            <div className="columns">
                <div className="column is-6">
                  <ResponsiveContainer aspectRatio="16:10">
                    <GatsbyImage
                      image={data.allFile.edges[0].node.childImageSharp.gatsbyImageData}
                      style={{ position: 'absolute' }}
                      alt="The Collective Haus gang huddles around Marge for a team profile photo." />
                  </ResponsiveContainer>
                </div>
                <div className="column is-5 is-offset-1">
                  <h3>Book your cut today!</h3>
                  <p>The crew’s all here to make you look and feel great.</p>
                  <p>Stop by 273 Elm Street and book an appointment in-person or give us a call at (705) 470-5179 !</p>
                  <a className="button" href="https://www.vagaro.com/collectivehaus/book-now" target="_blank" rel="noreferrer">Book Now</a>
                </div>
            </div>
          </VerticalTitle>
      </div>
    );
}

const imageQuery = graphql`{
  allFile(filter: {name: {glob: "*-Contact-*"}}, sort: {fields: name}) {
    edges {
      node {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  }
}
`
