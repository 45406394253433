import React from 'react'

import { ResponsiveContainer } from './../ResponsiveContainer/ResponsiveContainer';

import * as s from './Impact.module.scss';

export const Impact = ( { mediaType, mediaSrc, videoPoster, alt }) => {
    let media;

    switch(mediaType){

        case 'video':
            media = <video 
                    title={alt}
                    poster={videoPoster ? videoPoster : ''}
                    src={mediaSrc ? mediaSrc : ''}
                    autoPlay
                    playsInline 
                    muted 
                    loop 
                ></video>
            break;

        case 'image':
            media = <img src={mediaSrc} alt={alt} />
            break;

        default:
            media = <h1 className="error">Provided media type not found</h1>;

    }

    return (
        <div className={s.impact}>
            <ResponsiveContainer aspectRatio="16:7.8">
                {media}
            </ResponsiveContainer>
        </div>
    )
}
