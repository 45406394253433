import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { TeamMember } from '../Team';

import video from './../../../videos/optimized/team/victoria-video.mp4';
import placeholderImg from './../../../images/CollectiveHaus_2023_VideoLoader.jpg';

export const Victoria = () => {
    const data = useStaticQuery(portraitQuery);
    const portrait = data.file.childImageSharp.gatsbyImageData;

    return (
        <TeamMember
            portrait={portrait}
            portraitAlt="Victoria, hairstylist, compassionate vegan and resident cyclist at Collective Haus smiles looking into the camera"
            video={video}
            videoAlt="Victoria the hairstylist spins to face the camera while giving the peace symbol"
            videoPlaceholder={placeholderImg}
            name="Victoria"
            title="Hairstylist"
            shortBio="Victoria is a über-talented hairstylist, compassionate vegan and resident cyclist at Collective Haus."
        >
            <p>
                Victoria fell in love with salons back when she was a little girl following her mother to her hair appointments. Years later, Victoria attended Marvel Beauty School in Toronto before returning home to dive into the industry head first. Fast forward a decade and she’s still loving it. When she’s not sheerin, clippin’ and cuttin’, you can find her cycling across town, hanging out with her dog and two cats or trying new foods with her husband.
            </p>
        </TeamMember>
    )
}

const portraitQuery = graphql`{
  file(name: {glob: "*-Victoria-Portrait"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
}
`
