// extracted by mini-css-extract-plugin
export var close = "Team-module--close--1C9dk";
export var content = "Team-module--content--r82Dw";
export var link = "Team-module--link--sI6S3";
export var logo = "Team-module--logo--oVdl8";
export var modal = "Team-module--modal--kb7dJ";
export var name = "Team-module--name--m7ENQ";
export var overlay = "Team-module--overlay--tPKXn";
export var portrait = "Team-module--portrait--hShWP";
export var team = "Team-module--team--ICPbM";
export var teamMember = "Team-module--teamMember--7R6lK";
export var teamMemberGrid = "Team-module--teamMemberGrid--Knt+V";
export var video = "Team-module--video--VGGkC";