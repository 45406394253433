import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { TeamMember } from '../Team';

import video from './../../../videos/optimized/team/sharon-video.mp4';
import placeholderImg from './../../../images/CollectiveHaus_2023_VideoLoader.jpg';

export const Sharon = () => {
    const data = useStaticQuery(portraitQuery);
    const portrait = data.file.childImageSharp.gatsbyImageData;

    return (
        <TeamMember
            portrait={portrait}
            portraitAlt="Sharon, hairstylist at Collective Haus smiling happily into the camera."
            video={video}
            videoAlt="Sharon the hairstylist glamorously blows her hair with a blowdryer."
            videoPlaceholder={placeholderImg}
            name="Sharon"
            title="Hairstylist"
            shortBio="Sharon is a calm and quirky stylist with a heart of gold and a deep love for cooking."
        >
            <p>
              Meet Sharon, a hairstylist originally from the small town of Killarney, nestled on the outskirts of Sudbury. 
              <br />
              <br />
              Growing up at her family's Lodge, she was surrounded by the beauty of nature and developed a deep appreciation for hospitality, art, and the culinary arts. It was here that she discovered her passion for working with her hands. She then took her talents to the Aveda Institute and Academy in Toronto, where she honed her skills and worked on perfecting her craft.  
              With a decade of experience working at Civello in Oakville, Ont, Sharon has built strong relationships with her colleagues and guests, using this opportunity to develop her skills and dabble with creative and editorial hairstyling. Now, she is eager to bring her experience closer to home, creating hairstyles, while bringing a unique blend of skill and creativity to every head of hair she touches.  
            </p>
        </TeamMember>
    )
}

const portraitQuery = graphql`{
  file(name: {glob: "*-Sharon-Portrait"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
}
`
